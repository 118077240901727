import { Vector2d } from 'konva/lib/types';
import { MathUtils, Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import { merge } from '@/helpers/utils';
import {
  getBoxAttachPointRelativeTo,
  getOrderedControlPoints,
} from '@/modules/common/helpers/shapes';
import { wideLineSegmentToOrientedBoundingBox } from '@/modules/common/helpers/boundingBox';
import { ControlPoint, PotentialControlPointType } from '@/modules/common/types/shapes';
import { addCopy } from '@/store/recoil/floorplan/helper';
import { WallShape } from '@/store/recoil/shape';
import { AngledHighwayShapePersisted } from '@modules/common/types/floorPlan';
import { midpoint } from '@modules/connections/common/helpers';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import { getVectorRotatedAroundPoint } from '../common/helpers/math';
import { AngledHighwayShape } from './types';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { getProcess2EPEnds } from '@/modules/connections/connections/helpers/connections';
import { WideLineSegment } from '@/modules/common/types/general';
import { getLineSegmentsFromPoints } from '../shapes/controlPointsShapes/helpers';


export const getAngledHighwayDuplicate = (
  originShape: AngledHighwayShape | WallShape,
  offset: Vector2,
  overrides: any = {},
): AngledHighwayShape | WallShape => {
  const newShapeControlPoints = [];

  let prevCPId = null;
  let newCPId = null;
  let nextCPId = null;

  const controlPointsAmount = originShape.properties.controlPoints.length;
  getOrderedControlPoints(originShape.properties.controlPoints).forEach((originCP, index) => {
    newCPId = nextCPId || uuid();

    const isLastCP = controlPointsAmount - 1 === index;
    nextCPId = isLastCP ? null : uuid();

    const newCP: ControlPoint = {
      id: newCPId,
      prev: prevCPId,
      next: nextCPId,
      position: new Vector2(originCP.position.x, originCP.position.y).add(offset),
    };

    prevCPId = newCPId;

    newShapeControlPoints.push(newCP);
  });

  return merge(
    {
      ...originShape,
      name: addCopy(originShape),
      id: uuid(),
      properties: {
        ...originShape.properties,
        controlPoints: newShapeControlPoints,
      },
    },
    overrides,
  );
};

export const snapToClosestAxis = (respectiveOrigin: Vector2, point: Vector2) => {
  const diff = point.clone().sub(respectiveOrigin);

  return Math.abs(diff.y) < Math.abs(diff.x)
    ? point.clone().setY(respectiveOrigin.y)
    : point.clone().setX(respectiveOrigin.x);
};

export const getHighwaySegmentIdContainingPoint = (
  highway: AngledHighwayShape | ProcessTwoEPShape,
  point: Vector2,
): number => {
  const controlPoints = highway?.properties?.controlPoints;

  if (!controlPoints) return -1;

  const { width } = highway.parameters;
  const highwayLineSegments = getLineSegmentsFromPoints(controlPoints.map((item) => item.position));

  return getWideLineSegmentContaingPoint(
    highwayLineSegments.map((lineSegment) => ({ points: lineSegment, width })),
    point,
  );
};

export const getProcessEPIndexContainingPoint = (shape: ProcessTwoEPShape, point: Vector2): number => {
  const endPoints = getProcess2EPEnds(shape);

  return getWideLineSegmentContaingPoint(endPoints, point);
};

const getWideLineSegmentContaingPoint = (segments: WideLineSegment[], point: Vector2): number => {
  for (let i = 0; i < segments.length; ++i) {
    const {
      points: { start, end },
      width,
    } = segments[i];
    const segmentEndFromStart = end.clone().sub(start);
    const angleInDegrees = MathUtils.radToDeg(
      Math.atan2(segmentEndFromStart.y, segmentEndFromStart.x),
    );

    // rotate segment and point so the segment is on the x-axis
    const axisAlignedSegmentVector = getVectorRotatedAroundPoint(
      segmentEndFromStart,
      new Vector2(0, 0),
      -angleInDegrees,
    );
    const respectivePoint = getVectorRotatedAroundPoint(
      point.clone().sub(start),
      new Vector2(0, 0),
      -angleInDegrees,
    );

    if (
      respectivePoint.y <= width / 2 &&
      respectivePoint.y >= -(width / 2) &&
      respectivePoint.x >= 0 &&
      respectivePoint.x <= axisAlignedSegmentVector.x
    ) {
      return i;
    }
  }

  return -1;
};

export const getHighwaySegmentContainingPoint = (
  highway: AngledHighwayShape,
  point: Vector2d,
): WideLineSegment | null => {
  const segmentId = getHighwaySegmentIdContainingPoint(
    highway,
    new Vector2(point.x, point.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE),
  );

  if (segmentId === -1) {
    return null;
  }

  return getSegment(highway, segmentId);
};

export const getProcessEPContainingPoint = (
  shape: ProcessTwoEPShape,
  point: Vector2d,
): WideLineSegment | null => {
  const segmentId = getProcessEPIndexContainingPoint(
    shape,
    new Vector2(point.x, point.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE),
  );

  if (segmentId === -1) {
    return null;
  }

  return getProcess2EPEnds(shape)[segmentId];
};

export const getSegment = (
  highway: AngledHighwayShapePersisted,
  segmentId: number,
): WideLineSegment | null => getSegments(highway)[segmentId] ?? null;

export const getSegments = ({
  properties,
  parameters,
}: AngledHighwayShapePersisted): WideLineSegment[] => {
  const lines: WideLineSegment[] = [];

  for (let j = 0; j < properties.controlPoints.length - 1; j++) {
    lines.push({
      points: {
        start: properties.controlPoints[j].position,
        end: properties.controlPoints[j + 1].position,
      },
      width: parameters.width,
    });
  }

  return lines;
};

export const getSegmentAttachPoint = (segment: WideLineSegment): Vector2 =>
  midpoint(segment.points.start, segment.points.end).multiplyScalar(SHAPE_TO_CANVAS_SCALE);

export const getSegmentAttachPointRelativeTo = (
  segment: WideLineSegment,
  relativeTo: Vector2,
): Vector2 => {
  const obb = wideLineSegmentToOrientedBoundingBox(segment);
  const attachPoint = getBoxAttachPointRelativeTo(obb, relativeTo);

  return attachPoint;
};
