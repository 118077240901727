import { create } from 'zustand';

import { UserPreference, UserPreferences } from '../types';
import { DEFAULT_USER_PREFERENCES, UserPreferenceName } from '../constants';

type UserPreferenceState = {
  preferences: UserPreferences;
};

type UserPreferenceActions = {
  initialize(preferences: UserPreference[]): void;
  getPreference: <T extends UserPreferenceName>(name: T) => UserPreferences[T];
  setPreference: <T extends UserPreferenceName>(name: T, value: UserPreferences[T]) => void;
  reset(): void;
};

const INITIAL_STATE: UserPreferenceState = {
  preferences: DEFAULT_USER_PREFERENCES,
};

export const useUserPreferencesStore = create<UserPreferenceState & UserPreferenceActions>(
  (set, get) => ({
    ...INITIAL_STATE,
    initialize(data) {
      const { preferences } = get();
      const newPreferences = { ...preferences };
      data.forEach((item) => {
        newPreferences[item.name as string] = item.value;
      });

      set({
        preferences: newPreferences,
      });
    },

    getPreference(name) {
      return get().preferences[name];
    },

    setPreference(name, value) {
      set({
        preferences: {
          ...get().preferences,
          [name]: value,
        },
      });
    },

    reset() {
      set(INITIAL_STATE);
    },
  }),
);
