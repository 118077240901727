import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { v4 as uuid } from 'uuid';
import { ErrorNotification, Notification, NotificationType } from '../helpers/types';
import { enableMapSet } from 'immer';
import { persist, createJSONStorage } from 'zustand/middleware'

export type NotificationStore = {
  notifications: Map<string, Notification | ErrorNotification>;
  hasUnreadNotifications: boolean;
};

type NotificationActions = {
  addNotification(message: string, type: Exclude<NotificationType, NotificationType.ERROR>): void;
  addErrorNotification(notification: ErrorNotification): void;
  removeNotification(id: string): void;
  clearNotifications(): void;
  markMessagesAsRead(): void;
};

const INITIAL_STATE: NotificationStore = {
  notifications: new Map(),
  hasUnreadNotifications: false,
};

enableMapSet();

export const useNotificationStore = create<NotificationStore & NotificationActions>()(
  persist(
    immer((set) => ({
      ...INITIAL_STATE,

      addNotification: (description, type) => {
        const id = uuid();
        const newNotification: Notification = {
          id,
          type,
          description,
          timestamp: new Date(),
          read: false,
        };

        set((state) => {
          const newNotifications = new Map([[id, newNotification], ...state.notifications]);
          state.notifications = newNotifications;
          state.hasUnreadNotifications = true;
        });
      },

      addErrorNotification: (notification: ErrorNotification) =>
        set((state) => {
          const newNotifications = new Map([
            [notification.id, notification],
            ...state.notifications,
          ]);
          state.notifications = newNotifications;
          state.hasUnreadNotifications = true;
        }),

      removeNotification: (id) =>
        set((state) => {
          const notification = state.notifications.get(id);
          if (notification) {
            state.notifications.delete(id);
            state.hasUnreadNotifications = false;
          }
        }),

      markMessagesAsRead: () =>
        set((state) => {
          state.notifications.forEach((notification) => {
            notification.read = true;
          });
          state.hasUnreadNotifications = false;
        }),

      clearNotifications: () =>
        set((state) => {
          state.notifications = new Map();
          state.hasUnreadNotifications = false;
        }),
    })),
    {
      name: 'notifications-store',
      storage: {
        getItem: (name) => {
          const str = sessionStorage.getItem(name);
          return {
            state: {
              ...JSON.parse(str).state,
              notifications: new Map(JSON.parse(str).state.notifications),
            },
          };
        },
        setItem: (name, newValue) => {
          const str = JSON.stringify({
            state: {
              ...newValue.state,
              notifications: Array.from(newValue.state.notifications.entries()),
            },
          });
          sessionStorage.setItem(name, str);
        },
        removeItem: (name) => sessionStorage.removeItem(name),
      },
    },
  ),
);
