import { create } from 'zustand';

type DebugStore = {
  open: boolean;
  autodeskDebug: boolean;
  groupMemberSelectionForceEnabled: boolean;
};

type DebugActions = {
  setOpen(open: boolean): void;
  toggleAutodeskDebug(): void;
  toggleGroupMemberSelectionEnabled(): void;
};

const INITIAL_STATE: DebugStore = {
  open: false,
  autodeskDebug: false,
  groupMemberSelectionForceEnabled: false,
};

export const useDebugStore = create<DebugStore & DebugActions>((set, get) => ({
  ...INITIAL_STATE,

  setOpen(open: boolean) {
    set({
      open,
    });
  },

  toggleAutodeskDebug() {
    set({
      autodeskDebug: !get().autodeskDebug,
    });
  },

  toggleGroupMemberSelectionEnabled() {
    set({
      groupMemberSelectionForceEnabled: !get().groupMemberSelectionForceEnabled,
    });
  },
}));
