import { create } from 'zustand';

export type SnackbarStore = {
  message: string
};

type SnackbarActions = {
  showSnackbar(message: string): void;
  resetMessage(): void;
};

const INITIAL_STATE: SnackbarStore = {
  message: null,
};

export const useSnackbarStore = create<SnackbarStore & SnackbarActions>((set) => ({
  ...INITIAL_STATE,

  showSnackbar: (message: string) => {
    set({
      message,
    });
  },

  resetMessage: () => {
    set({
      message: null
    });
  },
}));
