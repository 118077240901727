import { supportsLoadCarriers } from '@/modules/common/helpers/shapes';
import { isAreaShape, isHighwayShape } from '@/modules/common/types/guards';
import { UnifiedVehicleDetails } from '@/modules/vehicles';
import { isVertical } from '@/modules/workspace/helpers/shape';
import { DTShape, ShapeType } from '@modules/common/types/shapes';

export const AREA_MINIMUM_SIZE = 1000;
export const OBSTACLE_MINIMUM_SIZE = 50;
export const WALL_MINIMUM_LENGTH = 50;

export function getMinimumSizeByShapeType(type: ShapeType) {
  const minSize =
    type === ShapeType.WALL
      ? WALL_MINIMUM_LENGTH
      : type === ShapeType.OBSTACLE
      ? OBSTACLE_MINIMUM_SIZE
      : AREA_MINIMUM_SIZE;

  return minSize;
}

export function getMinimumSize(
  shape: DTShape, 
  vehicle?: UnifiedVehicleDetails,
  loadCarrier?: {width: number, length: number}
) {
  let minWidth: number;
  let minHeight: number;
  const minSize = getMinimumSizeByShapeType(shape.type);

  if (isHighwayShape(shape)) {
    const isHorizontal = shape.properties.width >= shape.properties.height;
    const minWidthHighway =
      vehicle.width + 2 * shape.parameters.margin;
    const minHeightHighway =
      vehicle.length + 2 * shape.parameters.margin;
    
    minWidth = isHorizontal ? minHeightHighway : minWidthHighway
    minHeight = isHorizontal ? minWidthHighway : minHeightHighway
  } else if (isAreaShape(shape) && supportsLoadCarriers(shape.type)) {
    const { direction } = shape.parameters

    const minWidthAreasWithLoad =
      loadCarrier.width;
    const minHeightAreasWithLoad =
      loadCarrier.length;

    minWidth = isVertical(direction) ? minHeightAreasWithLoad : minWidthAreasWithLoad
    minHeight = isVertical(direction) ? minWidthAreasWithLoad : minHeightAreasWithLoad
  } else if (isAreaShape(shape)) {
    const { direction } = shape.parameters

    const minWidthAreasNoLoad =
      (vehicle.width + 2 * shape.parameters.margin);
    const minHeightAreasNoLoad =
      (vehicle.length + 2 * shape.parameters.margin);
    
    minWidth = isVertical(direction) ? minHeightAreasNoLoad : minWidthAreasNoLoad
    minHeight = isVertical(direction) ? minWidthAreasNoLoad : minHeightAreasNoLoad
  } else {
    minWidth = minSize;
    minHeight = minSize;
  }

  return {
    width: minWidth,
    height: minHeight,
  }
}
