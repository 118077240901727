import { useRecoilCallback } from 'recoil';

import { useArtefacts } from '@/modules/artefacts';
import { supportsVehicleTypes } from '@modules/common/helpers/shapes';
import { isAreaShape, isPositionShape } from '@modules/common/types/guards';
import { useAutoSave } from '@modules/floorplan';
import { allShapesSelector } from '@recoil/shapes';
import { enabledVehicleIdsState } from '@/modules/vehicles';

export const useVehicleTypes = () => {
  const { save } = useAutoSave();
  const { update: updateArtefacts } = useArtefacts();

  const setVehicleIds = useRecoilCallback(
    ({ set, snapshot }) =>
      async (ids: string[]) => {
        const shapes = await snapshot.getPromise(allShapesSelector);
        set(enabledVehicleIdsState, ids);
        const shapeIdsWithNewSupportedVehicles: string[] = [];

        const newShapes = shapes.map((shape) => {
          if (!(isAreaShape(shape) || isPositionShape(shape))) {
            return shape;
          }

          const currentVehicleIds: string[] = shape.parameters.supportedVehicleIds ?? [];
          const shapeAlreadyHasSupportedTypes = currentVehicleIds.some((item) =>
            ids.includes(item),
          );
          if (shapeAlreadyHasSupportedTypes) {
            return shape;
          }

          shapeIdsWithNewSupportedVehicles.push(shape.id);

          return {
            ...shape,
            parameters: {
              ...shape.parameters,
              supportedVehicleIds: [ids.at(0)],
            },
          };
        });

        set(allShapesSelector, newShapes);
        updateArtefacts(shapeIdsWithNewSupportedVehicles);
        await save();
      },
    [save, updateArtefacts],
  );

  const deleteVehicleIds = useRecoilCallback(
    ({ set, snapshot }) =>
      async (ids: string[]) => {
        const enabledIds = await snapshot.getPromise(enabledVehicleIdsState);
        const newEnabledIds = enabledIds.filter((id) => !ids.includes(id));
        set(enabledVehicleIdsState, newEnabledIds);
        const shapeIdsWithNewSupportedVehicles = [];

        const shapes = await snapshot.getPromise(allShapesSelector);
        const newShapes = shapes.map((shape) => {
          if (
            !(isAreaShape(shape) || isPositionShape(shape)) ||
            !supportsVehicleTypes(shape.type)
          ) {
            return shape;
          }

          const currentSupportedVehicleIds: string[] = shape.parameters.supportedVehicleIds ?? [];
          const shapeAlreadyHasSupportedTypes = currentSupportedVehicleIds.some((item) =>
            newEnabledIds.includes(item),
          );
          if (shapeAlreadyHasSupportedTypes) {
            return shape;
          }

          shapeIdsWithNewSupportedVehicles.push(shape.id);

          return {
            ...shape,
            parameters: {
              ...shape.parameters,
              supportedVehicleIds: [newEnabledIds.at(0)],
            },
          };
        });

        set(allShapesSelector, newShapes);
        updateArtefacts(shapeIdsWithNewSupportedVehicles);
        await save();
      },
    [save, updateArtefacts],
  );

  return {
    setVehicleIds,
    deleteVehicleIds,
  };
};
