import { useCanvasStore } from '@modules/canvas';
import { create } from 'zustand';

import { createImageObject, deserialize } from '../converters/sevenSense';
import { map, mapReferenceImage } from '../mappers/sevenSense';
import { Module } from '@/modules/common/types/navigation';

type SevenSenseState = {
  fileName: string;
  ids: string[];
};

type SevenSenseActions = {
  changeModule(newModule: Module): void;
  deleteFile(): void;
  initialize(file?: File): Promise<void>;
  reset(): void;
  setFile(file?: File): Promise<void>;
};

const INITIAL_STATE: SevenSenseState = {
  fileName: null,
  ids: [],
};

export const LOCALIZATION_ELEMENT_ID = 'SevenSenseLocalization';

export const useSevenSenseStore = create<SevenSenseState & SevenSenseActions>((set, get) => ({
  ...INITIAL_STATE,
  changeModule(newModule: Module) {
    if (newModule === Module.COMMISSIONING_GATE) {
      useCanvasStore.getState().instance.updateInteractivity(LOCALIZATION_ELEMENT_ID, {
        selectable: false,
        draggable: false,
        rotatable: false,
      });
    } else if (newModule === Module.COMMISSIONING_SETUP) {
      useCanvasStore.getState().instance.updateInteractivity(LOCALIZATION_ELEMENT_ID, {
        selectable: true,
        draggable: true,
        rotatable: false,
      });
    }
  },
  deleteFile() {
    set({
      fileName: null,
    });
    useCanvasStore.getState().instance.removeElements(get().ids);
  },

  async initialize(file?: File) {
    await get().setFile(file);
  },

  reset() {
    set(INITIAL_STATE);
  },

  async setFile(file?: File) {
    if (!file) {
      return;
    }

    get().deleteFile();
    const ids: string[] = [];

    // draw reference image
    const layout = deserialize(await file.text());
    const image = await createImageObject(file);
    useCanvasStore
      .getState()
      .instance.updateElement(mapReferenceImage(image, layout.width, layout.height));
    ids.push(LOCALIZATION_ELEMENT_ID);

    // draw parsed elements
    const elements = map(layout);
    useCanvasStore.getState().instance.updateElements(elements);
    ids.push(...elements.map((item) => item.id));

    // update state
    set({
      ids,
      fileName: file.name,
    });
  },
}));
