import { removeItems } from '@/modules/common/helpers/array';
import { useRecoilCallback } from 'recoil';
import { layoutAllFlowIds, layoutFlowSelector, layoutFlowsSelector } from '../store/layout';
import { FlowStopType } from '../types';
import { useGetRelatedFlowIdsOfShapeIds } from './useGetRelatedFlowIdsOfShapeIds';
import { useGetShapeIdsRelatedToFlowId } from './useGetShapeIdsRelatedToFlowId';

export const useFlow = () => {
  const getRelatedShapeIds = useGetShapeIdsRelatedToFlowId();
  const getRelatedFlowIdsOfShapes = useGetRelatedFlowIdsOfShapeIds();

  const getFlow = useRecoilCallback(
    ({ snapshot }) =>
      async (flowId: string) =>
        await snapshot.getPromise(layoutFlowSelector(flowId)),
  );

  const getRelatedFlowIdsOfGroup = useRecoilCallback(
    ({ snapshot }) =>
      async (groupId: string): Promise<string[]> => {
        const allFlows = await snapshot.getPromise(layoutFlowsSelector);
        const relatedFlowIds = new Set<string>();
        allFlows.forEach((flow) => {
          if (
            flow.intakeFlowStop.id === groupId &&
            flow.intakeFlowStop.type === FlowStopType.AREA_GROUP
          ) {
            relatedFlowIds.add(flow.id);
          }
          if (
            flow.deliveryFlowStop.id === groupId &&
            flow.deliveryFlowStop.type === FlowStopType.AREA_GROUP
          ) {
            relatedFlowIds.add(flow.id);
          }
        });
        return Array.from(relatedFlowIds);
      },
  );

  const deleteFlows = useRecoilCallback(
    ({ set }) =>
      (ids: string[]) => {
        set(layoutAllFlowIds, (previous) => removeItems(previous, ids));
      },
    [],
  );

  return {
    getFlow,
    getRelatedShapeIds,
    getRelatedFlowIdsOfShapes,
    getRelatedFlowIdsOfGroup,
    deleteFlows,
  };
};
