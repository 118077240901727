import { Snackbar as MuiSnackbar, SnackbarCloseReason, SnackbarContent } from '@mui/material';
import { useEffect, useState } from 'react';

import { theme } from '@/modules/common/components/theme';
import { AUTO_HIDE_DURATION } from '../constants';
import { useSnackbarStore } from '../store/useSnackbarStore';

type Props = { 
  justifyContent: 'flex-start' | 'center' | 'flex-end',
  rightOffset: string,
  bottomOffset: string,
};

export function Snackbar({ justifyContent, rightOffset, bottomOffset }: Props) {
  const [snackPack, setSnackPack] = useState<readonly string[]>([]);
  const { message, resetMessage } = useSnackbarStore();
  const [activeSnackbar, setActiveSnackbar] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (snackPack.length && activeSnackbar == null) {
      // Set a new snack when we don't have an active one
      setActiveSnackbar(snackPack[0]);
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
      resetMessage();
    } else if (snackPack.length && activeSnackbar != null && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, open, activeSnackbar, resetMessage]);

  useEffect(() => {
    if (message != null)     
      setSnackPack((prev) => [...prev, message]);
  }, [message]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'escapeKeyDown') {
      return;
    }

    setOpen(false);
  };

  const handleExited = () => {
    setActiveSnackbar(undefined);
  };

  return ( 
    <MuiSnackbar
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      sx={{ 
        '&:hover': { cursor: 'default' }, 
        justifyContent,
        '&.MuiSnackbar-root': {
          position: 'fixed',
          bottom: bottomOffset,
          right: rightOffset,
        },
      }}
    >
      <SnackbarContent
        style={{
          display: 'block',
          textAlign: "center",
          minWidth: 0,
          maxWidth:'300px',
          borderRadius: '4px',
          backgroundColor: theme.palette.neutral.darker,
        }}
        message={activeSnackbar}
      /> 
    </MuiSnackbar>
  );
}