import { Vector2 } from 'three';
import { create } from 'zustand';

import { ActivePointsDrawingState, ControlPoint } from '@/modules/common/types/shapes';

type WorkspaceStore = {
  activePointsDrawingState: ActivePointsDrawingState;
  shapeInEditModeIdState: string;
};

type WorkspaceActions = {
  setActivePointsDrawingState(value: ActivePointsDrawingState): void;
  setActivePointsDrawingIdState(id: string): void;
  setControlPoints(controlPoints: ControlPoint[]): void;
  setInterimEndPointPos(interimEndPointPos: Vector2): void;
  setShapeInEditModeIdState(id: string): void
  reset(): void;
};

const INITIAL_STATE: WorkspaceStore = {
  activePointsDrawingState: {
    id: null,
    type: null,
    controlPoints: [],
    interimStartControlPoint: null,
    interimEndPointPos: null,
    width: null,
  },
  shapeInEditModeIdState: null,
};

export const useWorkspaceStore = create<WorkspaceStore & WorkspaceActions>((set, get) => ({
  ...INITIAL_STATE,

  setActivePointsDrawingState(value) {
    set({
      activePointsDrawingState: value
    });
  },

  setActivePointsDrawingIdState(id) {
    const { activePointsDrawingState } = get();
    set({
      activePointsDrawingState: { ...activePointsDrawingState, id }
    });
  },

  setControlPoints(controlPoints) {
    const { activePointsDrawingState } = get();
    set({
      activePointsDrawingState: { ...activePointsDrawingState, controlPoints }
    });
  },

  setInterimEndPointPos(interimEndPointPos) {
    const { activePointsDrawingState } = get();
    set({
      activePointsDrawingState: { ...activePointsDrawingState, interimEndPointPos }
    });
  },

  setShapeInEditModeIdState(id) {
    set({ shapeInEditModeIdState: id });
  },

  reset() {
    set(INITIAL_STATE);
  },
}));
