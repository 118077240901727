import { getRecoilPromise } from 'recoil-nexus';
import { v4 as uuid } from 'uuid';
import { create } from 'zustand';

import { map } from '@/modules/commissioning/mappers/areas/areaMapper';
import { allShapesSelector } from '@/store/recoil/shapes';
import { useCanvasStore } from '@modules/canvas';
import {
  GeneratedFloorPlanArtefacts,
  GeneratedLoadPosition,
  GeneratedLocation,
} from '@modules/floorplanService';
import { LayoutDelta } from '../helpers/types';

type FloorPlanState = {
  groupId: string;
  loadPositions: GeneratedLoadPosition[];
  locations: GeneratedLocation[];
  shapeIds: string[];
};

type FloorPlanActions = {
  initialize(artifacts: GeneratedFloorPlanArtefacts, layoutDelta?: LayoutDelta): Promise<void>;
  group(): void;
  reset(): void;
  ungroup(): void;
};

const INITIAL_STATE: FloorPlanState = {
  groupId: uuid(),
  locations: [],
  loadPositions: [],
  shapeIds: [],
};

export const useFloorPlanStore = create<FloorPlanState & FloorPlanActions>((set, get) => ({
  ...INITIAL_STATE,

  async initialize(artifacts) {
    const canvas = useCanvasStore.getState()?.instance;
    if (!canvas) return;

    const shapes = await getRecoilPromise(allShapesSelector);
    const elements = map(shapes, artifacts, get().groupId);
    canvas.updateElements(elements);

    set({
      shapeIds: elements.map((item) => item.id),
      locations: artifacts.locations,
      loadPositions: artifacts.loadPositions,
    });
  },

  group() {
    const canvas = useCanvasStore.getState().instance;
    const groupId = canvas.group(get().shapeIds);
    canvas.updateInteractivity(groupId, {
      rotatable: false,
    });

    set({
      groupId,
    });
  },

  ungroup() {
    const canvas = useCanvasStore.getState().instance;
    canvas.ungroup(get().groupId);
    // TODO deselect
  },

  reset() {
    set(INITIAL_STATE);
  },
}));
