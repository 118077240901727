import { ArrowSmallDown, ArrowSmallRight } from '@/assets/icons';
import { useFloorPlanState } from '@modules/floorplan';
import { ListItemButton, ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { memo, useCallback, useMemo, useState } from 'react';
import { selectorFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { CONTEXT, contextState } from '../../../store/recoil/input';
import shapeAtom from '../../../store/recoil/shape/atom';
import { PropertiesTitle } from '../../common/components/PropertiesTitle';
import { ShapeItem } from '../../layers/components';
import { shapeGroupState } from '../atom';
import { groupIdIsInSelectionSelector } from '../selectors';

type GroupItemProps = {
  id: string;
};

const shapeGroupContentsSelector = selectorFamily({
  key: 'shapeGroup/byId/children',
  get:
    (groupId: string) =>
    ({ get }) => {
      const groupChildrenIds = get(shapeGroupState(groupId)).children;
      const shapes = groupChildrenIds.map((id) => get(shapeAtom(id)));
      return shapes;
    },
});

const GroupItemComponent = ({ id }: GroupItemProps) => {
  const [group, setGroup] = useRecoilState(shapeGroupState(id));
  const groupedShapes = useRecoilValue(shapeGroupContentsSelector(id));
  const isSelected = useRecoilValue(groupIdIsInSelectionSelector(id));

  const { saveFloorPlan } = useFloorPlanState();

  const groupNameChangeHandler = useCallback(
    (value) => {
      setGroup((current) => ({
        ...current,
        name: value,
      }));
      saveFloorPlan();
    },
    [setGroup, saveFloorPlan],
  );

  const setContext = useSetRecoilState(contextState);
  const [open, setOpen] = useState(false);
  const mode = useRecoilValue(modeSelector);

  const handleClick = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  const onNameClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const containerStyle = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'start',
      gap: 1,

      height: 'auto',
      paddingRight: '0px',
      backgroundColor: isSelected ? 'neutral.grey' : 'shades.light',

      '&:hover': {
        backgroundColor: isSelected ? 'neutral.grey' : 'shades.light',
        border: 0,
      },
      '&:focus-within': {
        backgroundColor: isSelected ? 'neutral.grey' : 'initial',
      },
    }),
    [isSelected],
  );

  const disabled = mode !== WorkspaceMode.EDITABLE;

  return (
    <>
      <ListItemButton
        alignItems='flex-start'
        disableRipple
        sx={containerStyle}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            minWidth: (theme) => theme.spacing(3),
            fontSize: 24,
            marginTop: '0.5rem',
          }}
        >
          {open ? <ArrowSmallDown /> : <ArrowSmallRight />}
        </ListItemIcon>
        <PropertiesTitle
          value={group.name}
          editable={!disabled}
          onClick={onNameClick}
          onChange={groupNameChangeHandler}
          onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
          onBlur={() => setContext(CONTEXT.WORKSPACE)}
          multiline
          fontSize={14}
          fontWeight={400}
          maxRowsPassive={2}
          maxRowsActive={3}
          sx={{ flex: '1 1 auto', marginTop: '4px', marginBottom: '3px' }}
        />
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {groupedShapes.map((shape) => (
            <ShapeItem
              key={shape.id}
              canBeLocked={false}
              canBeHidden={false}
              label={shape.name}
              id={shape.id}
              level={0}
              disabled={disabled}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const GroupItem = memo(GroupItemComponent);
