import { create } from 'zustand';

import { useCanvasStore } from '@/modules/canvas';
import { ToolState as DTTools } from '@modules/common/types/tools';

type ToolState = {
  tool: DTTools;
};

type ToolActions = {
  setTool(tool: DTTools): void;
  reset(): void;
};

const INITIAL_STATE: ToolState = {
  tool: DTTools.SELECT,
};

export const useToolStore = create<ToolState & ToolActions>((set, get) => ({
  ...INITIAL_STATE,

  setTool: (tool: DTTools) => {
    if (tool === get().tool) return;
    
    const canvas = useCanvasStore.getState().instance;

    switch (tool) {
      case DTTools.PAN:
        canvas.activateTool('Pan');
        break;
      case DTTools.DRAW:
        canvas.activateTool('Draw');
        break;
      default:
        canvas.activateTool('Select');
        break;
    }   

    set({
      tool,
    });
  },

  reset: () => {
    set(INITIAL_STATE);
  },
}));
