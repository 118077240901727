import { useCanvasStore } from '@modules/canvas';
import { create } from 'zustand';
import { Vector3 } from 'three';

import { deserialize } from '../converters/navitech';
import { map } from '../mappers/navitech';
import { calculateZoomInRange } from '@/modules/canvas/helpers';

type NavitechState = {
  fileName: string;
  ids: string[];
};

type NavitechActions = {
  deleteFile(): void;
  initialize(file?: File): Promise<void>;
  setFile(file?: File): Promise<void>;
  scale(zoom: number): void;
  reset(): void;
};

const INITIAL_STATE: NavitechState = {
  fileName: null,
  ids: [],
};

export const useNavitechStore = create<NavitechState & NavitechActions>((set, get) => ({
  ...INITIAL_STATE,

  deleteFile() {
    set({
      fileName: null,
    });

    useCanvasStore.getState().instance.removeElements(get().ids);
  },

  async initialize(file?: File) {
    await get().setFile(file);
  },

  reset() {
    set(INITIAL_STATE);
  },

  scale(zoom: number) {
    const size = calculateZoomInRange(zoom, 0, 75, 20, 500);
    useCanvasStore.getState().instance.updateTransformation(get().ids, {
      size: new Vector3(size, size, 1),
    });
  },

  async setFile(file?: File) {
    if (!file) {
      return;
    }

    const { instance } = useCanvasStore.getState();
    const elements = map(deserialize(await file.text()));

    get().deleteFile();
    set({
      ids: elements.map((item) => item.id),
      fileName: file.name,
    });

    instance.updateElements(elements);
    get().scale(instance.getZoom());
  },
}));
