import { create } from 'zustand';

export type HistoryState = {
  hasUndo: boolean;
  hasRedo: boolean;
};

type HistoryActions = {
  reset(): void;
};

const INITIAL_STATE: HistoryState = {
  hasUndo: false,
  hasRedo: false,
};

export const useHistoryStore = create<HistoryState & HistoryActions>((set) => ({
  ...INITIAL_STATE,
  
  reset: () => {
    set(INITIAL_STATE);
  },
}));
