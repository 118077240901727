import { create } from 'zustand';
import { Vector3 } from 'three';
import { useCanvasStore } from '@modules/canvas';

import { deserialize } from '../converters/kollmorgen';
import { Reflector, ReflectorType } from '../helpers/types';
import { map } from '../mappers/kollmorgen';
import { FLAT_REFLECTOR_SIZE, ROUND_REFLECTOR_SIZE } from '../helpers/constants';
import { calculateZoomInRange } from '@/modules/canvas/helpers';

type KollmorgenState = {
  fileName: string;
  reflectors: Reflector[];
  roundIds: string[];
  flatIds: string[];
};

type KollmorgenActions = {
  deleteFile(): void;
  initialize(file?: File): Promise<void>;
  loadReflectors(reflectors: readonly Reflector[], fileName: string): void;
  setFile(file?: File): Promise<void>;
  scale(zoom: number): void;
  reset(): void;
};

const INITIAL_STATE: KollmorgenState = {
  fileName: null,
  reflectors: [],
  roundIds: [],
  flatIds: [],
};

export const useKollmorgenStore = create<KollmorgenState & KollmorgenActions>((set, get) => ({
  ...INITIAL_STATE,

  deleteFile() {
    useCanvasStore.getState().instance.removeElements(get().reflectors.map((item) => item.id));

    set({
      fileName: null,
      flatIds: [],
      reflectors: [],
      roundIds: [],
    });
  },

  async initialize(file) {
    await get().setFile(file);
  },

  loadReflectors(reflectors: Reflector[], fileName: string) {
    const { instance } = useCanvasStore.getState();

    set({
      fileName,
      reflectors,
      roundIds: reflectors
        .filter((item) => item.type === ReflectorType.Round)
        .map((item) => item.id),
      flatIds: reflectors
        .filter((item) => item.type !== ReflectorType.Round)
        .map((item) => item.id),
    });

    instance.updateElements(map(reflectors));
    get().scale(instance.getZoom());
  },

  reset() {
    set(INITIAL_STATE);
  },

  scale(zoom: number) {
    const flatSize = calculateZoomInRange(zoom, 0, 200, FLAT_REFLECTOR_SIZE, 2000);
    const roundSize = calculateZoomInRange(zoom, 0, 200, ROUND_REFLECTOR_SIZE, 2000);

    useCanvasStore
      .getState()
      .instance.updateTransformation(get().flatIds, {
        size: new Vector3(flatSize, flatSize, 1),
      })
      .updateTransformation(get().roundIds, {
        size: new Vector3(roundSize, roundSize, 1),
      });
  },

  async setFile(file?: File) {
    if (!file) {
      return;
    }

    const reflector = deserialize(await file.text());
    get().deleteFile();
    get().loadReflectors(reflector, file.name);
  },
}));
