import { useRecoilCallback } from 'recoil';
import { Vector3 } from 'three';

import {
  shapesAngleState,
  shapesHeightState,
  shapesPositionState,
  shapesWidthState,
} from '@/components/PropertiesPanel/store/dimension';
import { clampAngle180 } from '@/modules/common/helpers/math';
import { isPointsProperties } from '@/modules/common/types/guards';
import { positionAfterRotation } from '@/modules/workspace/helpers/shape';
import { shapeProperty } from '@/store/recoil/shape';
import { shapesSelector } from '@/store/recoil/shapes';

export const useOnShapeAngleChange = () => {

  const onShapeAngleChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newValue: number) => {
        const { x, y } = await snapshot.getPromise(shapesPositionState);
        const width = await snapshot.getPromise(shapesWidthState);
        const height = await snapshot.getPromise(shapesHeightState);
        const angle = await snapshot.getPromise(shapesAngleState);

        const newPosition = positionAfterRotation(
          new Vector3(x, y),
          width,
          height,
          angle,
          newValue,
        );
        set(shapesPositionState, newPosition);
        set(shapesAngleState, newValue);
      },
    [],
  );

  const onUnmountAngleChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (ids: string[]) => {
        const currentShapes = await snapshot.getPromise(shapesSelector(ids));
        currentShapes.forEach(({ id, properties }) => {
          if (isPointsProperties(properties)) {
            // TODO: points property support
            return;
          }

          set(shapeProperty(id), {
            ...properties,
            r: clampAngle180(properties.r),
          });
        });
      },
    [],
  );

  return {
    onShapeAngleChange,
    onUnmountAngleChange,
  };
};
