import { useRecoilCallback } from 'recoil';

import { allConnectionsSelector } from '@modules/connections/connections';
import { allCrossingSelector } from '@/modules/connections/crossings';
import { highwayIdsAtom } from '../../../../store/recoil/shapes/highway';
import { getConnectionLackingRoadIds } from '../helpers';
import { prevalidationState } from '../store';
import { angledHighwayIdsState } from '@/modules/angledHighways';

export const useConnectionLackingRoadIdsValidator = () => {
  const validateConnectionLackingRoadIds = useRecoilCallback(
    ({ snapshot, set }) =>
      async (syncResultsWithStore = true) => {
        const allRoadIds = await snapshot.getPromise(highwayIdsAtom);
        const allAngledHighwayIds = await snapshot.getPromise(angledHighwayIdsState);
        const allConnections = await snapshot.getPromise(allConnectionsSelector);
        const allCrossings = await snapshot.getPromise(allCrossingSelector);

        const connectionLackingRoadIds = getConnectionLackingRoadIds(
          [...allRoadIds, ...allAngledHighwayIds],
          allConnections,
          allCrossings,
        );

        if (syncResultsWithStore) {
          set(prevalidationState, (current) => {
            if (
              current.connectionLackingRoadIds.length === 0 &&
              connectionLackingRoadIds.length === 0
            ) {
              return current;
            }

            return {
              ...current,
              connectionLackingRoadIds,
            };
          });
        }

        return connectionLackingRoadIds;
      },
    [],
  );

  return {
    validateConnectionLackingRoadIds,
  };
};
