
import { getRecoilPromise } from 'recoil-nexus';
import { create } from 'zustand';

import { calculateShapesBoundingBox } from '@/modules/common/helpers/shapes';
import { allConnectionsSelector } from '@/modules/connections/connections';
import { allDistantConnectionsSelector } from '@/modules/connections/distant';
import { GeneratedFloorPlanArtefacts } from '@/modules/floorplanService';
import { layoutFlowsSelector } from '@/modules/flows/store/layout';
import { map as areaMapper } from '@/modules/salesWorkspace/mappers/area/areaMapper';
import { map as connectionMapper } from '@/modules/salesWorkspace/mappers/connection/connectionMapper';
import { map as flowMapper } from '@/modules/salesWorkspace/mappers/flow/flowMapper';
import { DTShape } from '@/store/recoil/shape';
import { allShapesSelector } from '@/store/recoil/shapes';
import { useCanvasStore } from '@modules/canvas';

type ShapeState = {
  shapeDict: Map<string, DTShape>;
  currentId: string;
};

type ShapeActions = {
  initialize(artifacts: GeneratedFloorPlanArtefacts): Promise<void>;
  selectShape(id: string): void;
  reset(): void;
};

const INITIAL_STATE: ShapeState = {
  shapeDict: new Map(),
  currentId: null,
};

export const useShapeStore = create<ShapeState & ShapeActions>((set, get) => ({
  ...INITIAL_STATE,

  async initialize(artifacts) {

    const canvas = useCanvasStore.getState()?.instance;
    if (!canvas) return;

    const shapes = await getRecoilPromise(allShapesSelector);
    const connections = await getRecoilPromise(allConnectionsSelector);
    const distantConnections = await getRecoilPromise(allDistantConnectionsSelector);
    const flows = await getRecoilPromise(layoutFlowsSelector);
    const boundingBox = calculateShapesBoundingBox(shapes);

    const areaElements = areaMapper(shapes, artifacts)
    const connectionElements =  connectionMapper(connections, distantConnections, boundingBox)
    const flowElements = await flowMapper(flows, shapes, boundingBox)

    const elements = [...areaElements, ...connectionElements, ...flowElements]; 

    canvas.updateElements(elements);

    set({      
      shapeDict: new Map(shapes.map((item) => [item.id, item])),
    });
  },

  selectShape(id: string) {
    set({
      currentId: get().shapeDict.has(id) ? id : null,
    });
  },

  reset() {
    set(INITIAL_STATE);
  },

}))
