import { GroupElement } from '@thive/canvas';
import { Vector3 } from 'three';
import { create } from 'zustand';

import { useCanvasStore } from '@modules/canvas';
import { LayoutDelta } from '../helpers/types';
import { useFloorPlanStore } from './useFloorPlanStore';

type LayoutState = {
  delta: Vector3;
  deltaAngle: 0;
  initialDelta: Vector3;
};

type LayoutActions = {
  initialize(delta?: LayoutDelta): void;
  reset(): void;
  setCenterOffset(center: Vector3): void;
  setXOffset(value: number): void;
  setYOffset(value: number): void;
  updateGroupTransformation(group: GroupElement): void;
};

const INITIAL_STATE: LayoutState = {
  delta: new Vector3(),
  deltaAngle: 0,
  initialDelta: new Vector3(),
};

export const useLayoutStore = create<LayoutState & LayoutActions>((set, get) => ({
  ...INITIAL_STATE,

  initialize(deltas?: LayoutDelta): void {
    const { groupId } = useFloorPlanStore.getState();
    const { instance } = useCanvasStore.getState();
    const groupElement = instance.getElement(groupId) as GroupElement;
    const delta = deltas?.delta ?? new Vector3();

    // Bounding box of the grouped shapes is not always at (0,0)
    // We need to store this offset and apply it when exporting
    const initialDelta = groupElement.position
      .clone()
      .sub(groupElement.size.clone().divideScalar(2))
      .floor()
      .setZ(0);

    set({ delta, initialDelta });

    instance.updateTransformation(groupElement.id, {
      position: calculateCenter(groupElement, delta).clone(),
    });
  },

  reset() {
    set(INITIAL_STATE);
  },

  setCenterOffset(center) {
    const { groupId } = useFloorPlanStore.getState();
    const groupElement = useCanvasStore.getState().instance.getElement(groupId) as GroupElement;
    const position = center.clone().sub(groupElement.size.clone().divideScalar(2)).floor();

    get().setXOffset(position.x);
    get().setYOffset(position.y);
  },

  setXOffset(value) {
    const { groupId } = useFloorPlanStore.getState();
    const { instance } = useCanvasStore.getState();
    const groupElement = instance.getElement(groupId) as GroupElement;
    const newDelta = get().delta.clone().setX(value);

    set({
      delta: newDelta,
    });

    instance.updateTransformation(groupId, {
      position: calculateCenter(groupElement, newDelta).clone(),
    });
  },

  setYOffset(value) {
    const { groupId } = useFloorPlanStore.getState();
    const { instance } = useCanvasStore.getState();
    const groupElement = instance.getElement(groupId) as GroupElement;
    const newDelta = get().delta.clone().setY(value);

    set({
      delta: newDelta,
    });

    instance.updateTransformation(groupId, {
      position: calculateCenter(groupElement, newDelta).clone(),
    });
  },

  updateGroupTransformation(group) {
    set({
      delta: group.position.clone().sub(group.size.clone().divideScalar(2)).floor().setZ(0),
    });
  },
}));

const calculateCenter = (element: GroupElement, position: Vector3) =>
  position.clone().add(element.size.clone().divideScalar(2));
