import { create } from 'zustand';

import { useFloorPlanStore, useSevenSenseStore } from '@/modules/commissioning/store';
import { Module } from '@modules/common/types/navigation';

type ModuleState = {
  module: Module;
};

type ModuleActions = {
  setModule(module: Module): void;
  reset(): void;
};

const INITIAL_STATE: ModuleState = {
  module: Module.COMMISSIONING_SETUP,
};

export const useModuleStore = create<ModuleState & ModuleActions>((set, get) => ({
  ...INITIAL_STATE,

  setModule: (module: Module) => {
    const current = get().module;
    useSevenSenseStore.getState().changeModule(module);
    if (current !== module) {
      if (module === Module.COMMISSIONING_GATE) {
        useFloorPlanStore.getState().ungroup();
      } else {
        useFloorPlanStore.getState().group();
      }
    }

    set({
      module,
    });
  },

  reset: () => {
    set(INITIAL_STATE);
  },
}));
