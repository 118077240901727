import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { OnChangeHandler } from '../types';

export const useChangeEvent = (
  value: string,
  onBlur: () => void,
  onChange: OnChangeHandler<string>,
  inputRef: MutableRefObject<HTMLInputElement>,
  multiline: boolean,
) => {
  const [innerValue, setInnerValue] = useState<string>(value);
  const [touched, setTouched] = useState(false);
  const [innerError, setError] = useState<string | undefined>();

  const onBlurInner = useCallback(() => {
    setInnerValue(value);
    inputRef.current.scrollTo(0, 0);
    onBlur?.();
  }, [onBlur, inputRef, value]);

  const onChangeInner = useCallback(
    (value) => {
      if (multiline) {
        setInnerValue(value.replace(/[\n\r]/g, ''));
      } else {
        setInnerValue(value);
      }

      setTouched(true);
    },
    [multiline],
  );

  useEffect(() => {
    if (touched) {
      const result = onChange?.(innerValue);
      Promise.resolve(result).then((result) => {
        const error = result && result.error;
        setError(error || undefined);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerValue]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return {
    onChangeInner,
    onBlurInner,
    innerValue,
    innerError,
  };
};
