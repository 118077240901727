import navAtom from '@recoil/nav';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { Module } from '../types/navigation';
import { useConfig } from './useConfig';

export const useNavigation = () => {
  const navigate = useNavigate();
  const setNav = useSetRecoilState(navAtom);
  const { portal } = useConfig();
  const { projectId, floorPlanId, groupId } = useParams();

  const goToBuilder = useCallback(
    (groupId: string, floorPlanId: string) => navigate(`builder/${groupId}/version/${floorPlanId}`),
    [navigate],
  );

  const updateUrl = useCallback((projectId: string, groupId: string, floorPlanId: string) => {
    window.history.replaceState(
      null,
      '',
      `/${projectId}/builder/${groupId}/version/${floorPlanId}`,
    );
  }, []);

  const goToProject = useCallback((id: string) => navigate(`${id}`), [navigate]);

  const goToPortal = useCallback(
    (redirect = false) => {
      if (portal.url) {
        let { url } = portal;

        if (projectId) {
          url += `/projects/${projectId}/`;
        }

        if (redirect) {
          window.location.href = url;
        }
        window.window.open(url);
      } else {
        throw new Error('No portal url was found in configuration');
      }
    },
    [projectId, portal],
  );

  const goToModule = useRecoilCallback(
    ({ snapshot }) =>
      async (module: Module) => {
        const currentNav = await snapshot.getPromise(navAtom);

        if (module !== currentNav && currentNav === Module.VISUALISATION) {
          navigate(`/${projectId}/builder/${groupId}/version/${floorPlanId}`);
        }

        setNav(module);
      },
    [floorPlanId, navigate, projectId, setNav],
  );

  const goToTracker = useCallback(
    (id: string, floorPlanVersionId?: string) => {
      let base = `/${projectId}/builder/${groupId}/version/${floorPlanId}/tracker/${id}`;
      if (floorPlanVersionId) {
        base += `/floorPlan/${floorPlanVersionId}`;
      }
      navigate(base);
    },
    [navigate, groupId, projectId, floorPlanId],
  );

  return {
    goToBuilder,
    goToModule,
    goToPortal,
    goToProject,
    goToTracker,
    updateUrl,
  };
};
